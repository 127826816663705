<template>
  <div>
    <v-row class="text-center">
      <v-col cols="12">
        <img :src="reportInfo.companyLogo" height="120" alt="logo_empresa"/>
      </v-col>
    </v-row>

    <v-divider class="mb-3"></v-divider>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h4 font-weight-black">{{ reportInfo.testName }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <span class="font-weight-black">Nombre:</span>
      </v-col>
      <v-col cols="4">
        {{ reportInfo.userName }}
      </v-col>
      <v-col cols="2">
        <span class="font-weight-black">Género:</span>
      </v-col>
      <v-col cols="4">
        {{ reportInfo.userGender }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <span class="font-weight-black">Cédula:</span>
      </v-col>
      <v-col cols="4">
        {{ reportInfo.userIdentificationNumber }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <span class="font-weight-black">Fecha Inicio:</span>
      </v-col>
      <v-col cols="4">
        {{ reportInfo.startDate }}
      </v-col>
      <v-col cols="2">
        <span class="font-weight-black">Fecha Fin:</span>
      </v-col>
      <v-col cols="4">
        {{ reportInfo.endDate }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <span class="font-weight-black">Puesto:</span>
      </v-col>
      <v-col cols="10">
        {{ reportInfo.profileName }}
      </v-col>
    </v-row>

    <v-divider class="mt-2"></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    reportInfo: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.row + .row {
  margin-top: 0;
}
</style>
