<template>
  <div class="row">
    <v-col sm="3">
      <countdown
        ref="timer"
        v-slot="{ days, hours, minutes, seconds }"
        :auto-start="false"
        :time="time"
        :transform="transformSlotProps"
        @end="onCountdownEnd"
      >
        <v-row>
          <v-col class="pa-0 text-center" sm="12">
            <small>Su perfil estará activo por:</small>
          </v-col>

          <v-col class="pa-0" sm="3">
            <h1 class="text-count">
              {{ days }}
            </h1>
            <small class="pl-1">Días</small>
          </v-col>
          <v-col class="pa-0" sm="3">
            <h1 class="text-count">
              {{ hours }}
            </h1>
            <small>Horas</small>
          </v-col>
          <v-col class="pa-0" sm="3">
            <h1 class="text-count">
              {{ minutes }}
            </h1>
            <small>Minutos</small>
          </v-col>
          <v-col class="pa-0" sm="3">
            <h1 class="text-count">
              {{ seconds }}
            </h1>
            <small>Segundos</small>
          </v-col>
        </v-row>
      </countdown>
    </v-col>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

export default {
  props: {
    time: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState('user', ['loggedUser']),
  },

  mounted() {
    this.startCountdown();
  },

  methods: {
    startCountdown() {
      this.$refs.timer.start();
    },

    stopCountdown() {
      this.$refs.timer.abort();
    },

    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },

    onCountdownEnd() {
      window.location = window.Routing.generate(
        'secure_profile_timeout_logout'
      );
    },
  },
};
</script>

<style>
.text-count {
  line-height: 0.7;
  font-size: 30px;
}
</style>
