<template>
  <v-dialog
    v-model="showFactorSelectionModal"
    persistent
    scrollable
    :max-width="1300"
  >
    <v-card class="pt-0">
      <v-card-title class="headline">
        <v-row>
          <v-col :cols="filterColumnSize">
            <v-select
              v-model="factorFilters.searchTest"
              label="Filtrar por Test"
              :items="getPsychometricTestNames"
              multiple
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col :cols="filterColumnSize">
            <v-text-field
              v-model="factorFilters.searchFactor"
              label="Buscar Factor..."
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col v-if="profile.isMerito" cols="2">
            <v-checkbox
              v-model="showFactorsMDT"
              label="Factores MDT?"
            ></v-checkbox>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn color="green darken-1" text @click="closeModal">
              Cancelar
            </v-btn>

            <v-btn color="primary" @click="updateSelectedProfileFactors">
              Guardar Factores
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-form ref="factorsForm" lazy-validation>
          <v-row v-for="(factor, index) in getFilteredFactors" :key="index">
            <v-col cols="1">
              <v-checkbox v-model="factor.node.isSelected"></v-checkbox>
            </v-col>
            <v-col cols="3">
              {{ factor.node.name }} ({{ factor.node.abbreviation }})
            </v-col>
            <v-col v-if="!profile.isMerito || !showFactorsMDT" cols="4">
              <v-text-field
                v-model="factor.node.relatedCompetence"
                label="Competencia Correlación"
                :disabled="!factor.node.isSelected"
                outlined
              ></v-text-field>
            </v-col>
            <v-col v-else cols="4">
              <v-select
                v-model="factor.node.relatedCompetence"
                label="Competencia Correlación"
                item-text="node.name"
                item-value="node.name"
                :disabled="!factor.node.isSelected"
                :items="factorMDTTypes"
                :rules="factor.node.isSelected ? commonRules : []"
                outlined
              ></v-select>
            </v-col>
            <v-col v-if="profile.isMerito" cols="2">
              <v-select
                v-model="factor.node.level"
                label="Nivel"
                item-text="label"
                item-value="value"
                :items="profileLevel"
                :disabled="!factor.node.isSelected"
                :rules="factor.node.isSelected ? commonRules : []"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="2">
              {{ factor.node.test.abbreviation }}
            </v-col>
            <v-col
              v-if="
                !profile.isLineal && !profile.isMerito && factor.node.isSelected
              "
              cols="2"
            >
              <v-text-field
                v-model="factor.node.weightInTest"
                label="Peso"
                :rules="factor.node.isSelected ? commonRules : []"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    profile: {
      type: Object,
      required: true
    },

    showFactorSelectionModal: {
      type: Boolean,
      required: true
    },

    filterColumnSize: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      showFactorsMDT: true
    };
  },

  created() {
    this.getFactorsMDT();
    this.getPsychometricTests(true).then(() => {
      this.setSelectedFactors(this.profile.factors);
    });
  },

  computed: {
    ...mapState('test', ['factorFilters']),
    ...mapState('profile', ['profileLevel']),
    ...mapState('factorMDT', ['factorMDTTypes']),
    ...mapGetters('test', [
      'getPsychometricTestNames',
      'getFilteredFactors',
      'getSelectedFactors'
    ])
  },

  methods: {
    ...mapActions('factorMDT', ['getFactorsMDT']),
    ...mapActions('test', ['getPsychometricTests', 'setSelectedFactors']),
    updateSelectedProfileFactors() {
      const isFormValid = this.$refs.factorsForm.validate();

      if (!isFormValid) {
        return;
      }

      this.profile.factors = this.getSelectedFactors.map(factor => {
        return {
          factor: factor.node.id,
          name: factor.node.name,
          abbreviation: factor.node.abbreviation,
          relatedCompetence: factor.node.relatedCompetence,
          level: factor.node.level,
          weightInTest: parseFloat(factor.node.weightInTest),
          testName: factor.node.test.title
        };
      });

      this.$emit('close');
    },

    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.v-text-field__details {
  display: none !important;
}
</style>
