<template>
  <div>
    <div class="d-flex">
      <p class="page-title">Perfiles</p>

      <v-btn class="ml-auto" color="primary" dark @click="addNewItem">
        <v-icon dense class="mr-2">mdi-plus-circle</v-icon>
        Nuevo Perfil
      </v-btn>
    </div>

    <confirmation-modal
      content-alert="El perfil se colocará en estado Activo, le permitirá asignarlo a candidatos y descargarlo. No podrá realizar modificaciones de ningún tipo."
      :show="showDialog"
      @success="setStatus"
      @cancel="cancelStatus"
    >
    </confirmation-modal>

    <confirmation-modal
      :alert="false"
      :show="dialogDelete"
      title="Desea eliminar el perfil?"
      content="Esta acción enviará al perfil a la papelera y podrá ser recuperado posteriormente."
      accept-text="Eliminar"
      :is-delete-action="true"
      @cancel="dialogDelete = false"
      @success="deleteItemConfirm"
    >
      <v-alert
        v-if="itemToDelete && itemToDelete.node.users.totalCount > 0"
        text
        prominent
        type="error"
        icon="mdi-alert-octagon-outline"
      >
        <span>
          Este perfil tiene asociado
          <span class="font-weight-bold text-h6">
            {{ itemToDelete.node.users.totalCount }} candidatos
          </span>
          actualmente.
          <br />
          Si continua con esta operación los candidatos asociados serán
          eliminados
        </span>
      </v-alert>
    </confirmation-modal>

    <v-dialog v-model="showReportDialog" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Rango de Fecha del Reporte</h3>
        </v-card-title>
        <v-card-text>
          <v-select
            label="Fecha Inicio *"
            :items="[]"
            item-text="name"
            item-value="id"
            outlined
          ></v-select>

          <v-select
            label="Fecha Fin *"
            :items="[]"
            item-text="name"
            item-value="id"
            outlined
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="showReportDialog = false">
            Cancelar
          </v-btn>

          <v-btn color="primary" text @click="generateAndDownloadMassiveReport">
            Descargar Reporte Masivo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      id="profileTable"
      :headers="profileTableColumns"
      :items="getFilteredProfiles"
      :loading="isProfileTableLoading"
      show-expand
      item-key="node.id"
      :single-expand="true"
      loading-text="Cargando... por favor, espere"
      class="elevation-1"
    >
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-3 grey lighten-2">
          <massive-load-table :profile-id="item.node.id"></massive-load-table>
        </td>
      </template>
      <template #top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-col cols="3" style="margin-top: 25px">
            <v-text-field
              v-model="filter.searchBy"
              dense
              size="small"
              outlined
              placeholder="Buscar..."
            />
          </v-col>
        </v-toolbar>
      </template>
      <template #[`item.node.name`]="{ item }">
        {{ item.node.name }}
        <v-chip v-if="!item.node.active" class="ma-2" x-small>
          Borrador
        </v-chip>
      </template>
      <template #[`item.node.isLineal`]="{ item }">
        <v-icon v-if="item.node.isLineal" color="green">
          mdi-check-circle-outline
        </v-icon>
        <v-icon v-else color="red">mdi-cancel</v-icon>
      </template>
      <template #[`item.node.type`]="{ item }">
        <v-chip v-if="item.node.type" small>
          {{ item.node.type }}
        </v-chip>
        <span v-else>-</span>
      </template>
      <template #[`item.node.active`]="{ item }">
        <v-tooltip :disabled="!item.node.active" bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-switch
                v-model="item.node.active"
                :disabled="item.node.active"
                inset
                hide-details
                @change="changeStatus(item)"
              >
              </v-switch>
            </div>
          </template>
          <span>
            El Perfil se encuentra activo, no es posible desactivarlo.
          </span>
        </v-tooltip>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              small
              class="mr-2"
              :disabled="
                isProfileTableLoading || item.node.massiveLoads.totalCount === 0
              "
              style="font-size: 18px"
              @click="openMassiveReportModal(item)"
              v-on="on"
            >
              mdi-file-excel-outline
            </v-icon>
          </template>
          <span>Ver Reporte Comparativo</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              v-if="item.node.isMerito"
              small
              class="mr-2"
              :disabled="isProfileTableLoading"
              style="font-size: 20px"
              @click="openMRLReportModal(item)"
              v-on="on"
            >
              mdi-microsoft-excel
            </v-icon>
            <v-icon v-else small class="mr-2"> mdi-block-helper </v-icon>
          </template>
          <span v-if="item.node.isMerito">Ver Reporte MRL</span>
          <span v-else>No es perfil de concurso de Mérito</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :disabled="isProfileTableLoading"
              small
              class="mr-2"
              @click="cloneItem(item)"
              v-on="on"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Clonar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              v-if="!item.node.active"
              :disabled="isProfileTableLoading"
              small
              class="mr-2"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
            <v-icon v-else small class="mr-2"> mdi-block-helper </v-icon>
          </template>
          <span>{{
            !item.node.active
              ? 'Editar'
              : 'No es posible editar un perfil Activo'
          }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              dense
              :disabled="isProfileTableLoading"
              @click="deleteItem(item)"
              v-on="on"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template #no-data>
        <h6>No hay ningún elemento en la lista</h6>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConfirmationModal from '../general/modals/confirmationModal.vue';
import MassiveLoadTable from './table/MassiveLoadTable.vue';

export default {
  components: {
    ConfirmationModal,
    MassiveLoadTable,
  },

  data() {
    return {
      selectedProfile: null,
      itemToChange: null,
      itemToDelete: null,
      dialogDelete: false,
      showDialog: false,
      showReportDialog: false,
    };
  },

  computed: {
    ...mapGetters('profile', ['getFilteredProfiles']),
    ...mapState('profile', [
      'filter',
      'profileTableColumns',
      'isProfileTableLoading',
    ]),
  },

  created() {
    this.getProfiles();
  },

  methods: {
    ...mapActions('profile', [
      'getProfiles',
      'deleteProfile',
      'setStatusProfile',
      'generateAndDownloadReport',
    ]),

    editItem(item) {
      this.$router.push({
        name: 'editProfile',
        params: { id: item.node.id.split('/')[3] },
      });
    },

    cloneItem(item) {
      this.$router.push({
        name: 'cloneProfile',
        params: { id: item.node.id.split('/')[3] },
      });
    },

    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.dialogDelete = false;
      this.deleteProfile(this.itemToDelete.node.id);
    },

    addNewItem() {
      this.$router.push({ name: 'newProfile' });
    },

    changeStatus({ node }) {
      if (node.active) {
        this.itemToChange = node;
        this.showDialog = true;
      }
    },

    setStatus() {
      this.setStatusProfile(this.itemToChange);
      this.showDialog = false;
    },

    cancelStatus() {
      this.showDialog = false;
      this.getProfiles();
    },

    openMassiveReportModal(item) {
      this.selectedProfileId = item.node._id;
      this.generateAndDownloadMassiveReport();
    },

    openMRLReportModal(item) {
      // this.showReportDialog = true;
      this.selectedProfileId = item.node._id;
      this.generateAndDownloadMRLReport();
    },

    generateAndDownloadMassiveReport() {
      return this.generateAndDownloadReport({
        reportName: 'get_massive_report',
        profileId: this.selectedProfileId,
      }).then((response) => {
        if (response.data.status === 200) {
          window.open(`/excel${response.data.fileName}`, '_blank');

          this.showReportDialog = false;
          this.selectedProfileId = null;
        }
      });
    },

    generateAndDownloadMRLReport() {
      return this.generateAndDownloadReport({
        reportName: 'get_mrl_report',
        profileId: this.selectedProfileId,
      }).then((response) => {
        if (response.data.status === 200) {
          window.open(`/excel${response.data.fileName}`, '_blank');

          this.showReportDialog = false;
          this.selectedProfileId = null;
        }
      });
    },
  },
};
</script>

<style>
#profileTable td {
  cursor: pointer;
}
</style>
