<template>
  <div>
    <div class="d-flex">
      <p class="page-title">Pruebas Académicas</p>

      <v-btn class="ml-auto" color="primary" dark @click="addNewItem">
        <v-icon dense class="mr-2">mdi-plus-circle</v-icon>
        Nueva Prueba
      </v-btn>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogImportAcademicTests" persistent width="400">
        <v-card color="primary" dark>
          <v-card-text>
            Importando Pruebas Académicas. Por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUploadFile" persistent max-width="700">
      <v-card>
        <v-card-title class="headline">
          Carga Masiva de Pruebas Académicas
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                target="_blank"
                link
                to="/utils/Carga_Masiva_Prueba_Academica.xls"
                color="primary"
                v-on="on"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
                Descargar Formato
              </v-btn>
            </template>
            <span>Descargar Formato</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-form ref="uploadForm">
                <v-row class="mt-3">
                  <v-col cols="12">
                    <v-text-field
                      v-model="file.name"
                      label="Título *"
                      outlined
                      :rules="commonRules"
                      placeholder="Título de la Prueba Académica"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <vue-dropzone
                  id="customdropzone"
                  ref="myVueDropzone"
                  :duplicate-check="true"
                  :options="dropzoneOptions"
                  @vdropzone-file-added="getFile"
                  @vdropzone-removed-file="removeFile"
                >
                </vue-dropzone>
              </v-form>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="closeUploadForm">
              Cancelar
            </v-btn>
            <v-btn
              :disabled="!file.name || file.tests == null"
              color="primary"
              @click="uploadMassiveTestFile"
            >
              Subir Pruebas Académicas
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirmation-modal
      content-alert="La prueba se colocará en estado <b>Activo</b>, le permitirá asignarlo a candidatos y descargarlo. No podrá realizar modificaciones de ningún tipo."
      :show="showDialog"
      :loading="isActionInProgress"
      @cancel="cancelStatus"
      @success="setStatus"
    >
    </confirmation-modal>

    <confirmation-modal
      :alert="false"
      :show="dialogDelete"
      :loading="isActionInProgress"
      title="Desea eliminar la prueba académica?"
      content="Esta acción enviará a la prueba académica a la papelera y podrá ser recuperado posteriormente."
      accept-text="Eliminar"
      :is-delete-action="true"
      @cancel="dialogDelete = false"
      @success="deleteItemConfirm"
    >
      <v-alert
        v-if="
          itemToDelete &&
          itemToDelete.node.profiles.totalCount > 0 &&
          itemToDelete.node.answers.totalCount > 0
        "
        text
        prominent
        type="error"
        icon="mdi-alert-octagon-outline"
      >
        <span>
          Esta prueba tiene asociado
          <span class="font-weight-bold text-h6">
            {{ itemToDelete.node.profiles.totalCount }} perfil(es)
          </span>
          y
          <span class="font-weight-bold text-h6">
            {{ itemToDelete.node.answers.totalCount }} respuesta(s)
          </span>
          actualmente.
          <br />
          Si continua con esta operación los resultados de los candidatos
          asociados serán eliminados
        </span>
      </v-alert>
    </confirmation-modal>

    <v-data-table
      id="testTable"
      :headers="testTableColumns"
      :items="getFilteredAcademicTests"
      :loading="isTestTableLoading"
      loading-text="Cargando... por favor, espere"
      class="elevation-1"
    >
      <template #top>
        <v-toolbar flat>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                text
                rounded
                small
                color="primary"
                v-on="on"
                @click="dialogUploadFile = true"
              >
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </template>
            <span>Subir Pruebas Académicas</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-col cols="3" style="margin-top: 25px">
            <v-text-field
              v-model="filter.searchBy"
              dense
              size="small"
              outlined
              placeholder="Buscar..."
            />
          </v-col>
        </v-toolbar>
      </template>
      <template #[`item.node.title`]="{ item }">
        {{ item.node.title }}
        <v-chip v-if="!item.node.status" class="ma-2" x-small>
          Borrador
        </v-chip>
      </template>
      <template #[`item.node.status`]="{ item }">
        <v-switch
          v-model="item.node.status"
          :disabled="item.node.status"
          hide-details
          inset
          @change="changeStatus(item)"
        >
        </v-switch>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :disabled="isTestTableLoading"
              small
              class="mr-2"
              @click="cloneItem(item)"
              v-on="on"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Clonar</span>
        </v-tooltip>
        <v-tooltip v-if="!item.node.status" bottom>
          <template #activator="{ on }">
            <v-icon
              :disabled="isTestTableLoading"
              small
              class="mr-2"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-icon v-else disabled small class="mr-2"> mdi-block-helper </v-icon>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :disabled="isTestTableLoading"
              small
              class="mr-2"
              @click="getLink(item)"
              v-on="on"
            >
              mdi-file-pdf-outline
            </v-icon>
          </template>
          <span>Crear Reporte</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :disabled="isTestTableLoading"
              dense
              class="mr-2"
              @click="deleteItem(item)"
              v-on="on"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
      <template #no-data>
        <h6>No hay ningún elemento en la lista</h6>
      </template>
    </v-data-table>
    <v-file-input
      v-show="false"
      id="file"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      class="hidden"
      @change="uploadExcel"
    />
    <v-snackbar
      v-model="snackbar"
      left
      bottom
      color="primary"
      dark
      timeout="5000"
    >
      Deshacer acción en
      <v-progress-circular :value="value * 20" class="ml-3">
        {{ value }}
      </v-progress-circular>
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="cancelUpload">
          Cancelar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ConfirmationModal from '../../general/modals/confirmationModal.vue';

export default {
  components: {
    ConfirmationModal,
    vueDropzone: vue2Dropzone,
  },

  beforeRouteEnter(to, from, next) {
    if (to.name === 'academicTestImport') {
      next((vm) => {
        vm.dialogUploadFile = true;
      });
    } else {
      next();
    }
  },

  data: () => ({
    dialogUploadFile: false,
    showDialog: false,
    interval: {},
    value: 5,
    snackbar: false,
    itemToDelete: null,
    isUploaded: false,
    dialog: false,
    dialogDelete: false,
    dialogDeleteMultiple: false,
    passwordDialog: false,
    dropzoneOptions: {
      url: '#',
      maxFilesize: 2,
      uploadMultiple: false,
      thumbnailWidth: 80,
      thumbnailHeight: 80,
      dictFileTooBig: 'El archivo es muy grande',
      dictInvalidFileType: 'No se admite este tipo de archivo',
      dictCancelUpload: 'Cancelar',
      dictCancelUploadConfirmation: 'Desea cancelar la operación',
      headers: { 'My-Awesome-Header': 'header value' },
      createImageThumbnails: false,
      dictRemoveFile: 'Cancelar',
      acceptedFiles:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',

      addRemoveLinks: true,
      dictDefaultMessage: `<div style="background-image:url(https://cdn.vuetifyjs.com/images/parallax/material2.jpg)">
        <div class="fill-height repeating-gradient"></div>
      </div>`,
    },
  }),

  computed: {
    ...mapGetters('test', ['getFilteredAcademicTests']),
    ...mapState('test', [
      'file',
      'filter',
      'testTableColumns',
      'isActionInProgress',
      'isTestTableLoading',
      'dialogImportAcademicTests',
    ]),

    getDeleteIcon() {
      if (this.itemsToDelete.length === 0) {
        return 'mdi-delete-off';
      }

      return 'mdi-delete';
    },

    getColorIcon() {
      return this.isUploaded ? 'success' : 'secondary';
    },
  },

  created() {
    this.getAcademicTests();
  },

  methods: {
    ...mapActions('test', [
      'getAcademicTests',
      'deleteTests',
      'deleteTest',
      'updateTest',
      'massiveUpload',
      'setStatusTest',
      'exportPDF',
    ]),

    getLink({ node }) {
      // eslint-disable-next-line no-undef
      window.open(`${Routing.generate('get_pdf_report')}/${node._id}`, '_self');
    },

    cloneItem(item) {
      this.$router.push({ name: 'cloneTest', params: { id: item.node._id } });
    },

    addNewItem() {
      this.$router.push({ name: 'newTest' });
    },

    editItem(item) {
      this.$router.push({
        name: 'editTest',
        params: { id: item.node.id.split('/')[3] },
      });
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.itemToDelete = item;
    },

    deleteItemConfirm() {
      this.deleteTest(this.itemToDelete.node.id).then(() => {
        this.dialogDelete = false;
      });
    },

    closeUploadForm() {
      this.dialogUploadFile = false;
      this.$refs.uploadForm.reset();
      this.$refs.uploadForm.resetValidation();
    },

    isFormValid() {
      const hasFile = this.$refs.myVueDropzone.getAcceptedFiles().length >= 1;
      const hasName = this.$refs.uploadForm.validate();

      return hasFile && hasName;
    },

    uploadMassiveTestFile() {
      const formValid = this.isFormValid();

      if (formValid) {
        this.dialogUploadFile = false;

        this.massiveUpload().then((response) => {
          if (response.data.status === 'success') {
            this.$router.push({
              name: 'editTest',
              params: { id: response.data.testId },
            });
            this.$refs.myVueDropzone.removeAllFiles();

            this.$refs.uploadForm.reset();
            this.$refs.uploadForm.resetValidation();
          }
        });
      }
    },

    getFile(e) {
      if (e !== null && e !== undefined) {
        this.file.tests = e;
      }
    },

    removeFile() {
      this.file.tests = null;
    },

    getExcelFile() {
      const inputFile = document.querySelector('#file');

      inputFile.click();
    },

    uploadExcel(e) {
      if (e !== null && e !== undefined) {
        this.file.tests = e;
        this.handleSnackbar();
      }
    },

    handleSnackbar() {
      this.snackbar = true;
      this.isUploaded = true;
      this.interval = setInterval(() => {
        if (this.value === 1) {
          clearInterval(this.interval);
          setTimeout(() => (this.value = 5), 50);
          this.snackbar = false;
        }

        this.value -= 1;
      }, 1000);
    },

    destroyInterval() {
      this.snackbar = false;
      clearInterval(this.interval);
    },

    cancelUpload() {
      const input = document.querySelector('#file');

      input.value = '';

      this.destroyInterval();
      this.isUploaded = false;
      this.file.tests = null;

      setTimeout(() => (this.value = 5), 50);
    },

    changeStatus({ node }) {
      if (node.status) {
        this.itemToChange = node;
        this.showDialog = true;
      }
    },

    setStatus() {
      this.setStatusTest(this.itemToChange).then(() => {
        this.showDialog = false;
      });
    },

    cancelStatus() {
      this.showDialog = false;
      this.getAcademicTests();
    },

    createReport(item) {
      this.exportPDF(item);
    },
  },
};
</script>
<style>
#customdropzone {
  background-image: url('/build/images/dropzone-image.jpg');
  background-position: center;
  background-size: 250px;
  background-repeat: no-repeat;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}

#candidateTable td {
  cursor: pointer;
}

#testTable td {
  cursor: pointer;
}
</style>
