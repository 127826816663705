<template>
  <div>
    <div class="text-center">
      <v-dialog v-model="dialogDownloadingCandidates" persistent width="400">
        <v-card color="primary" dark>
          <v-card-text>
            Importando Candidatos. Por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-card>
      <v-card-title class="headline">
        Carga Masiva de Candidatos
        <v-spacer></v-spacer>
        <v-tooltip v-if="!editingMassiveLoad" bottom>
          <template #activator="{ on }">
            <v-btn
              target="_blank"
              link
              to="/utils/Carga_Masiva_Candidato.xls"
              color="primary"
              v-on="on"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
              Descargar Formato
            </v-btn>
          </template>
          <span>Descargar Formato de Ejemplo</span>
        </v-tooltip>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="uploadForm">
              <v-row class="mt-3">
                <v-col cols="12" class="py-0">
                  <v-select
                    v-model="massiveLoad.profile"
                    label="Perfil *"
                    :items="profiles"
                    item-text="node.name"
                    item-value="node.id"
                    outlined
                    :disabled="editingMassiveLoad"
                    :rules="commonRules"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="massiveLoad.description"
                    label="Lugar / Ubicación"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0 mb-2">
                  <h3 class="text-lg-h6">Seguridad Perfil</h3>
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-radio-group
                    v-model="massiveLoad.securityType"
                    row
                    :disabled="editingMassiveLoad"
                  >
                    <v-radio
                      v-for="securityType in securityTypes"
                      :key="securityType.label"
                      :label="securityType.label"
                      :value="securityType.value"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" class="py-0 mb-2">
                  <h3 class="text-lg-h6">Configuración</h3>
                  <v-divider></v-divider>
                </v-col>

                <v-col
                  v-if="massiveLoad.securityType === 'Usuario y Contraseña'"
                  cols="12"
                  class="py-0"
                >
                  <v-switch
                    v-model="massiveLoad.sendEmail"
                    style="margin-right: 10px"
                    label="Enviar Correo"
                    inset
                    :disabled="editingMassiveLoad"
                  ></v-switch>
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-switch
                    v-model="massiveLoad.isTimeLimited"
                    style="margin-right: 10px"
                    label="Limitar Tiempo Concurso"
                    inset
                    @change="clearDateFields"
                  ></v-switch>
                </v-col>

                <v-col v-if="massiveLoad.isTimeLimited" cols="6" class="py-0">
                  <v-datetime-picker
                    v-model="massiveLoad.startDate"
                    label="Fecha Inicio"
                    clear-text="Restablecer"
                    ok-text="Guardar"
                    :text-field-props="{
                      rules: commonRules,
                      outlined: true,
                      'append-icon': 'mdi-calendar',
                    }"
                  >
                  </v-datetime-picker>
                </v-col>

                <v-col v-if="massiveLoad.isTimeLimited" cols="6" class="py-0">
                  <v-datetime-picker
                    v-model="massiveLoad.endDate"
                    label="Fecha Finalización"
                    clear-text="Restablecer"
                    ok-text="Guardar"
                    :text-field-props="{
                      rules: commonRules,
                      outlined: true,
                      'append-icon': 'mdi-calendar',
                    }"
                  >
                  </v-datetime-picker>
                </v-col>

                <v-col cols="5" class="py-0">
                  <v-switch
                    v-model="massiveLoad.isIpLimited"
                    style="margin-right: 10px"
                    label="Limitar Rango de IP"
                    inset
                    @change="clearIpField"
                  ></v-switch>
                </v-col>

                <v-col v-if="massiveLoad.isIpLimited" cols="12" class="py-0">
                  <v-text-field
                    v-model="massiveLoad.ipRange"
                    label="Ej: 157.100.1.6 (Fija), 187.10.12.* (Comodín), 132.2.3.1-132.2.3.5 (Rango)"
                    :rules="commonRules"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!editingMassiveLoad">
                <v-col cols="12">
                  <vue-dropzone
                    id="customdropzone"
                    ref="myVueDropzone"
                    :duplicate-check="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="getFile"
                    @vdropzone-removed-file="removeFile"
                  >
                  </vue-dropzone>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="closeUploadForm">
            Cancelar
          </v-btn>
          <v-btn
            :loading="isActionInProgress"
            :disabled="isActionInProgress || massiveLoad.candidates == null"
            color="primary"
            @click="
              editingMassiveLoad
                ? updateMassiveLoadForm()
                : uploadMassiveTestFile()
            "
          >
            {{
              editingMassiveLoad ? 'Guardar Carga Masiva' : 'Subir Candidatos'
            }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },

  props: {
    editingMassiveLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dialogDownloadingCandidates: false,
      dropzoneOptions: {
        url: '#',
        maxFilesize: 2,
        uploadMultiple: false,
        thumbnailWidth: 80,
        thumbnailHeight: 80,
        dictFileTooBig: 'El archivo es muy grande',
        dictInvalidFileType: 'No se admite este tipo de archivo',
        dictCancelUpload: 'Cancelar',
        dictCancelUploadConfirmation: 'Desea cancelar la operación',
        headers: { 'My-Awesome-Header': 'header value' },
        createImageThumbnails: false,
        dictRemoveFile: 'Cancelar',
        acceptedFiles:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',

        addRemoveLinks: true,
        dictDefaultMessage: `<div style="background-image:url(https://cdn.vuetifyjs.com/images/parallax/material2.jpg)">
        <div class="fill-height repeating-gradient"></div>
      </div>`,
      },
    };
  },

  computed: {
    ...mapState('massiveLoad', [
      'massiveLoad',
      'securityTypes',
      'isActionInProgress',
    ]),

    ...mapState('profile', ['profiles']),
  },

  methods: {
    ...mapActions('massiveLoad', [
      'createMassiveLoad',
      'resetMassiveLoadForm',
      'updateMassiveLoad',
    ]),

    getFile(e) {
      if (e !== null && e !== undefined) {
        this.massiveLoad.candidates = e;
      }
    },

    clearDateFields() {
      if (!this.massiveLoad.isTimeLimited) {
        this.massiveLoad.startDate = null;
        this.massiveLoad.endDate = null;
      }
    },

    clearIpField() {
      if (!this.massiveLoad.isIpLimited) {
        this.massiveLoad.ipRange = null;
      }
    },

    isFormValid() {
      const hasFile = this.$refs.myVueDropzone.getAcceptedFiles().length >= 1;
      const hasProfile = this.$refs.uploadForm.validate();

      return hasFile && hasProfile;
    },

    closeUploadForm() {
      this.resetMassiveLoadForm();
      this.$refs.uploadForm.resetValidation();

      this.$emit('close');
    },

    updateMassiveLoadForm() {
      this.updateMassiveLoad().then(() => {
        this.$emit('close');
      });
    },

    uploadMassiveTestFile() {
      const formValid = this.isFormValid();

      if (formValid) {
        this.$emit('close');

        this.dialogDownloadingCandidates = true;

        this.createMassiveLoad().then((response) => {
          if (response.data.status === 'success') {
            this.$refs.myVueDropzone.removeAllFiles();

            this.closeUploadForm();

            this.dialogDownloadingCandidates = false;
          }
        });
      }
    },

    closeDropZone() {
      const input = document.querySelector('#file');

      input.value = '';

      this.destroyInterval();

      this.isUploaded = false;
      this.massiveLoad.candidates = null;
      this.$refs.myVueDropzone.removeAllFiles();

      setTimeout(() => {
        this.value = 5;
      }, 50);
    },

    removeFile() {
      this.massiveLoad.candidates = null;
    },
  },
};
</script>

<style>
.v-input__append-inner {
  margin-top: 12px !important;
}

#customdropzone {
  background-image: url('/build/images/dropzone-image.jpg');
  background-position: center;
  background-size: 250px;
  background-repeat: no-repeat;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}
</style>
