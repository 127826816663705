
#question-info {
  .v-card {
    border: solid 2px transparent;

    &:hover {
      border: solid 2px #2196f3;
    }
  }
}
