<template>
  <div>
    <v-row>
      <v-col cols="10">
        <p class="page-title">Comportamientos Observables</p>
      </v-col>
    </v-row>

    <v-dialog v-model="showEditModal" persistent max-width="770">
      <v-card>
        <v-card-title class="headline"> Editar Comportamiento </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <Form :behavior="behavior" :disabled="isActionInProgress"></Form>

          <v-card-actions class="mt-2">
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="closeModal">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              :loading="isActionInProgress"
              :disabled="isActionInProgress"
              @click="updateBehavior"
            >
              Guardar Cambios
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-data-table
      id="behaviorTable"
      :headers="behaviorTableColumns"
      :items="behaviors"
      item-key="node.id"
      :loading="isBehaviorTableLoading"
      loading-text="Cargando... por favor, espere"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.itemsPerPage"
      :server-items-length="pagination.totalItemsLength"
      class="elevation-1"
      @update:items-per-page="getBehaviors"
      @update:page="getBehaviors"
    >
      <template #top>
        <v-toolbar flat>
          <v-spacer></v-spacer>

          <v-col cols="4" style="margin-top: 25px">
            <v-select
              v-model="filter.testId"
              :items="psychometricTests"
              item-text="node.title"
              item-value="node._id"
              solo
              outlined
              clearable
              dense
              placeholder="Filtrar por Test..."
              :loading="psychometricTests.length === 0"
              :disabled="psychometricTests.length === 0"
              @change="filterFactorsByTest(filter.testId)"
            >
            </v-select>
          </v-col>

          <v-col cols="4" style="margin-top: 25px">
            <v-select
              v-model="filter.factorId"
              :items="factors"
              item-text="node.fullName"
              item-value="node._id"
              solo
              outlined
              clearable
              dense
              placeholder="Filtrar por Factor..."
              :loading="factors.length === 0"
              :disabled="factors.length === 0"
              @change="getBehaviors"
            >
            </v-select>
          </v-col>

          <v-col cols="3" style="margin-top: 25px">
            <v-text-field
              v-model="filter.searchBy"
              dense
              size="small"
              outlined
              placeholder="Buscar..."
              @keypress.enter="getBehaviors"
            />
          </v-col>

          <div>
            <v-btn color="primary" @click="getBehaviors">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </template>
      <template #[`item.factor.fullName`]="{ item }">
        <b>{{ item.factor.fullName }}</b>
        <v-chip small class="my-2">
          {{ item.factor.test.title }}
        </v-chip>
      </template>
      <template #[`item.color`]="{ item }">
        <v-chip v-if="item.color" text-color="white" :color="item.color">
          {{ item.color }}
        </v-chip>
        <span v-else>-</span>
      </template>
      <template #[`item.behaviorText`]="{ item }">
        <span v-html="item.behaviorText"></span>
      </template>
      <template #[`item.interpretationText`]="{ item }">
        <span v-html="item.interpretationText"></span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon small class="mr-2" @click="editBehavior(item)" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar Comportamiento</span>
        </v-tooltip>
      </template>
      <template #no-data>
        <h6>No hay ningún elemento en la lista</h6>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Form from './Form.vue';

export default {
  components: {
    Form
  },

  computed: {
    ...mapState('factor', ['factors']),
    ...mapState('test', ['psychometricTests']),
    ...mapState('behavior', [
      'filter',
      'pagination',
      'behavior',
      'behaviors',
      'showEditModal',
      'isActionInProgress',
      'behaviorTableColumns',
      'isBehaviorTableLoading'
    ])
  },

  created() {
    this.getBehaviors();
    this.getFactorsFullName();
    this.getPsychometricTests();
  },

  methods: {
    ...mapActions('test', ['getPsychometricTests']),
    ...mapActions('factor', ['getFactorsFullName']),
    ...mapActions('behavior', [
      'getBehaviors',
      'editBehavior',
      'closeModal',
      'updateBehavior'
    ]),

    filterFactorsByTest(testId) {
      this.getBehaviors();
      this.getFactorsFullName(testId);
    }
  }
};
</script>

<style>
#behaviorTable td {
  cursor: pointer;
}
</style>
